<template>
  <svg :class="props.class" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="0.741643"
      y1="15.7416"
      x2="14.7416"
      y2="1.74164"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <line
      x1="0.53033"
      y1="1.46967"
      x2="14.5303"
      y2="15.4697"
      stroke="currentColor"
      stroke-width="1.5"
    />
  </svg>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    class?: string
  }>(),
  {
    class: 'w-4 text-white'
  }
)
</script>

<style scoped></style>
